import React from "react";
import {useStaticQuery, graphql, Link} from "gatsby";
import BaseLayout from "../../../components/layout"
import Seo from "../../../components/seo";
import BookPageHeader from "../../../components/book-page-header";
import TextBlock from "../../../components/text-block";
import HorizontalRule from "../../../components/horizontal-rule";
import BookTOCCard from "../../../components/book-toc-card";
import ChapterSummary from "../../../components/chapter-summary";
import BreadcrumbTrail from "../../../components/breadcrumb-trail";
import kebabCase from "lodash/kebabCase";


export default function WritingFictionPage(props) {
  let data = useStaticQuery(query)
  const twilightBook = data.talesOfTwilight.nodes[0];
  const twilightChapters = data.allMdx.nodes;

  const sellBookText = [
    'Tales of Twilight is a 54-page novelette published in October 2022 that comprises of five short stories. All of the chapters are available both here on my website and through published ebook and print editions.',
    'You can read the stories here for free, or if you\'d like a more accessible format, ebook and print editions are available at the link below.'
  ]

  let breadcrumbList = [
    [
      {
        title: 'Home',
        url: '/'
      },
      {
        title: 'Library',
        url: '/writing/library/'
      },
      {
        title: 'Tales of Twilight',
      }
    ]
  ]

  return (
    <BaseLayout>
      <Seo title="Tales of Twilight" desc={twilightBook.seo_description} pathname={props.location.pathname} breadcrumbList={breadcrumbList} banner={twilightBook.seo_image}></Seo>

      <BookPageHeader book={twilightBook} chapters={twilightChapters} breadcrumbTrail={breadcrumbList[0]}></BookPageHeader>

      <div className="layout-base-w app-px-base">
        <div className="mt-40">
          <h3 className="text-header-2 text-mint text-center mb-12">{twilightBook.title}</h3>

          <div className="layout-reading-w mt-12 mb-32">
            <TextBlock text={twilightBook.summary}></TextBlock>
          </div>

          <div className="my-40 text-mint">
            <HorizontalRule ruleType="2"></HorizontalRule>
          </div>

          <div className={`base-w mt-16 mx-auto flex gap-10 flex-col md:flex-row`}>
            <div className="mx-auto mb-4 md:mb-0 w-full sm:[width:275px] sm:[flex-basis:275px]">
              <img className="border-2 border-mint bg-green w-full md:[margin-top:11px]" src={twilightBook.book_cover.portrait} alt={`${twilightBook.title} Book Cover`}/>
            </div>
            <div className="shrink grow flex-1">
              <TextBlock text={sellBookText} textStyle="italic" textSize="large"></TextBlock>
              <a href={twilightBook.purchase.url} target="_blank" rel="noopener noreferrer">
                <button className="btn btn-medium btn-gold mt-12">Purchase →</button>
              </a>
            </div>
          </div>

          <div className="my-40 text-mint">
            <HorizontalRule ruleType="4"></HorizontalRule>
          </div>

          <div className="my-40">
            <BookTOCCard chapters={twilightChapters}></BookTOCCard>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-40 my-40">
            {twilightChapters.map((chapter, index) => (
              <ChapterSummary key={index} chapter={chapter}></ChapterSummary>
            ))}
          </div>

          <div className="my-40 text-mint">
            <HorizontalRule ruleType="2"></HorizontalRule>
          </div>

          <div className="flex justify-center my-40">
            <ChapterSummary chapter={twilightChapters[0]} summaryType="cta"></ChapterSummary>
          </div>

        </div>
      </div>
    </BaseLayout>
  )
}

export const query = graphql`
  query WritingTalesOfTwilight {
    allMdx(
      sort: { fields: [frontmatter___chapter], order: ASC }
      filter: { frontmatter: { published: { eq: true }, collection: { eq: "Tales of Twilight" } } }
    ) {
      nodes {
        ...ArticleSnippet
      }
    }
    
    talesOfTwilight: allCollections(
      filter: { title: { eq: "Tales of Twilight" } }
      limit: 1
    ) {
      nodes {
        ...CollectionFields
      }
    }
  }
`;
