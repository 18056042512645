import React from "react";


/* USAGE
* - Include <Tooltip></Tooltip> component
* - pass in the tooltipText prop that defines what text to display
* - pass in a positionClass that defines how to display the text relative to the container
* - add classes "group" and "relative" to the container the tooltip is going inside
*/
export default function Tooltip(props) {
  let positionClass = "bottom-full"
  if (props.positionClass) {
    positionClass = props.positionClass
  }

  return (
    <div style={{'width': '200px', 'left': '50%', 'transform': 'translateX(-50%)'}} className={`absolute ${positionClass} flex flex-col items-center hidden mb-6 group-hover:flex`}>
      <span className="relative z-10 p-6 leading-none text-white bg-green shadow-lg text-body-1 text-left">{props.tooltipText}</span>
      <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
    </div>
  )
}

