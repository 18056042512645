import React from "react";
import { Link } from "gatsby"

export default function BookTOCCard(props) {
  return (
    <div>
      <h6 className="text-subheader-2 text-mint text-center border-b-base border-black mb-6 pb-6">Table of Contents</h6>
      {props.chapters.map((chapter, index) => (
        <Link to={`/${chapter.slug}`} key={index} className="text-link-bare">
          <div className="flex py-2">
            <h6 className="text-subheader-3 sm:text-subheader-1">{chapter.frontmatter.title}</h6>
            <div className="border-b-2 border-black border-dotted grow" style={{margin: '0 5px 1rem 5px'}}></div>
            <h6 className="text-subheader-3 sm:text-subheader-1">{chapter.frontmatter.chapter}</h6>
          </div>
        </Link>
      ))}
      {props.chapters.length === 0 &&(
        <h4>Nothing yet, check back soon.</h4>
      )}
    </div>
  )
}
