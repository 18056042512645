import React from "react";
import { Link } from "gatsby"

export default function BookTOCDropdown(props) {
  let dropdownTitle = 'Table of Contents';
  if (props.dropdownTitle) {
    dropdownTitle = props.dropdownTitle;
  }

  // Note border color set in global.scss
  let borderColor = 'white';
  if (props.borderColor) {
    borderColor = props.borderColor;
  }
  let borderColorClass;
  if (borderColor === 'white') {
    borderColorClass = 'TOCDropdown--white'
  } else if (borderColor === 'green') {
    borderColorClass = 'TOCDropdown--green'
  }

  let constrainWidth = true;
  if (props.constrainWidth !== undefined) {
    constrainWidth = props.constrainWidth;
  }
  let activeChapter = false;
  if (props.activeChapter) {
    activeChapter = props.activeChapter;
  }

  return (
    <div className={`TOCDropdown ${borderColorClass} flex cursor-pointer`}>
      <div className={`TOCDropdown_title ${!constrainWidth ? 'grow' : ''} border-b-base border-t-base border-l-base btn-full sm:btn-wide flex justify-center items-center`}>
        <h6 className="text-subheader-3">{dropdownTitle}</h6>
      </div>
      <div className={`TOCDropdown_drop border-base flex justify-center items-center`}>
        <p className="text-subheader-3 px-12">↓</p>
      </div>
      <div className="TOCDropdown_menu bg-offwhite w-full py-4">
        {props.chapters.map((chapter, index) => (
          <span key={index}>
            {(!activeChapter || (activeChapter && chapter.frontmatter.chapter !== activeChapter.frontmatter.chapter)) &&
              <Link key={index} to={`/${chapter.slug}`}>
                <div className="text-black px-8 py-4 hover:bg-lightblue">
                  <h6 className="text-subheader-3">{chapter.frontmatter.chapter}. {chapter.frontmatter.title}</h6>
                </div>
              </Link>
            }
            {(activeChapter && chapter.frontmatter.chapter === activeChapter.frontmatter.chapter) &&
              <div className="text-black px-8 py-4 bg-lightblue">
                <h6 className="text-subheader-3">{chapter.frontmatter.chapter}. {chapter.frontmatter.title}</h6>
              </div>
            }
          </span>
        ))}
      </div>
    </div>
  )
}
