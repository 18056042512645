import React from "react";
import { Link } from "gatsby"
import HorizontalRule from "./horizontal-rule";
import ArticleMetadata from "./article-metadata";
import BookTOCDropdown from "./book-toc-dropdown";
import BreadcrumbTrail from "./breadcrumb-trail";
import PurchaseBook from "./purchase-book";

export default function BookPageHeader(props) {

  const headerStyles = {
    backgroundImage: `url("${props.book.book_cover.landscape_backdrop}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  }

  return (
    <div className="w-full bg-green bg-cover [min-height:550px] md:[height:660px]" style={headerStyles}>
      <div className="layout-base-w pt-16 pb-16 md:pb-32 app-px-base text-offwhite h-full">
        <div className="flex flex-col justify-between h-full">
          <div>
            <BreadcrumbTrail breadcrumbTrail={props.breadcrumbTrail} makeOpaque={false}></BreadcrumbTrail>
            <HorizontalRule ruleType="1" reducePadding={-10}></HorizontalRule>
            <h6 className="text-subheader-1 mb-4" style={{'marginTop': '-5px'}}>{props.book.book_type}</h6>
            <h1 style={{'maxWidth': '620px'}} className="text-header-1 w-full pb-8">{props.book.title}{props.book.subtitle && <> {props.book.subtitle}</>}</h1>
          </div>
          <div>
            <div className="border-t-base border-b-base border-white py-8 flex justify-between items-center">
              <ArticleMetadata genreList={props.book.genres} publishedDate={props.book.published_date}></ArticleMetadata>
            </div>
            {(props.book.purchase.display || props.chapters.length > 0) &&
              <div className="flex justify-start mt-8 flex-col-reverse md:flex-row gap-8">
                {props.chapters.length > 0 &&
                  <BookTOCDropdown chapters={props.chapters}></BookTOCDropdown>
                }
                {props.chapters.length > 0 &&
                  <Link to={`/${props.chapters[0].slug}`}>
                    <button className="btn btn-full sm:btn-medium btn-white">Start First Chapter →</button>
                  </Link>
                }
                {props.book.purchase.display &&
                  <a href={props.book.purchase.url} target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-full sm:btn-medium btn-gold">{props.book.purchase.button_text} →</button>
                  </a>
                }

              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
