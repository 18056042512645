import React from "react";
import Tooltip from "./tooltip";

export default function PurchaseBook(props) {
  let displayFormat = 'bare';
  if (props.displayFormat) {
    displayFormat = props.displayFormat;
  }

  return (
    <>
      {props.book.purchase.display && (
        <>
          {displayFormat === 'bare' &&
            <a href={props.book.purchase.url} target="_blank" rel="noopener noreferrer">
              <div className="relative group cursor-pointer">
                <Tooltip tooltipText="I publish select chapters of my books on my website for free. The full story is available for purchase here."></Tooltip>
                <h6 className="text-button flex items-center">
                  Purchase
                  <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" height="10px" width="10px"><path d="M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0Zm.62,75.53a1.48,1.48,0,0,0,1.4,0,13.37,13.37,0,0,0,2.34-2.17c1-1.19,1.88-2.48,2.87-3.82l1.39.84c-2,3.34-4.06,6.44-7.12,8.76a11.06,11.06,0,0,1-5.58,2.33c-4.77.54-7.82-2.71-6.68-7.39,1-4.11,2.27-8.16,3.43-12.24S45,53.52,46.18,49.38a10.35,10.35,0,0,0,.21-1.24,2.45,2.45,0,0,0-2.21-3.06A21.2,21.2,0,0,0,42,45a3.53,3.53,0,0,1,.11-.71l.14-1,17.24-2.76-1.2,4.18h0l-.27,1Q54.27,58.81,50.49,72A8.32,8.32,0,0,0,50,74.14,1.86,1.86,0,0,0,50.62,75.53ZM54.76,31a6.22,6.22,0,0,1-6.18-6.28,6.21,6.21,0,1,1,12.42,0A6.29,6.29,0,0,1,54.76,31Z" fill="currentColor"></path></svg>
                </h6>
                <div className="w-full pt-4 flex justify-center items-center">
                  <div className="hover:text-lightblue active:text-black">
                    <svg fill="none" height="25px" width="25px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0,-952.36218)"><path fill="currentColor" d="m 8.0159573,961.36221 c -1.6569,0 -3,1.34315 -3,3 0,1.65685 1.3431,3 3,3 l 9.5624997,0 10.3125,48.65629 c 0.408,1.8316 1.6084,3.356 3.125,3.3438 l 50,0 c 1.5849,0.022 3.0427,-1.4149 3.0427,-3 0,-1.5851 -1.4578,-3.0224 -3.0427,-3 l -47.5625,0 -1.2813,-6 52.8438,0 c 1.3432,-0.01 2.6123,-1.0331 2.9062,-2.3438 l 7,-30.00004 c 0.3901,-1.74107 -1.122,-3.64346 -2.9062,-3.65625 l -67.4375,0 -1.625,-7.625 c -0.2839,-1.3321 -1.5755,-2.3764 -2.9375,-2.375 z m 17.8124997,16 15.625,0 1.5,9 -15.2187,0 z m 21.75,0 18.875,0 -1.5,9 -15.875,0 z m 25,0 15.6563,0 -2.0938,9 -15.0625,0 z m -43.5625,15 14.9375,0 1.5,9.00009 -14.5313,0 z m 21.0625,0 13.875,0 -1.5,9.00009 -10.875,0 z m 20,0 14.6563,0 -2.0938,9.00009 -14.0625,0 z m -28.0625,30.99999 c -5.4873,0 -10,4.5126 -10,10 0,5.4873 4.5127,10 10,10 5.4873,0 10,-4.5127 10,-10 0,-5.4873 -4.5127,-10 -10,-10 z m 28,0 c -5.4873,0 -10,4.5126 -10,10 0,5.4873 4.5127,10 10,10 5.4873,0 10,-4.5127 10,-10 0,-5.4873 -4.5127,-10 -10,-10 z m -28,6 c 2.2447,0 4,1.7553 4,4 0,2.2446 -1.7553,4 -4,4 -2.2445,0 -4,-1.7554 -4,-4 0,-2.2447 1.7555,-4 4,-4 z m 28,0 c 2.2447,0 4,1.7553 4,4 0,2.2446 -1.7553,4 -4,4 -2.2445,0 -4,-1.7554 -4,-4 0,-2.2447 1.7555,-4 4,-4 z" stroke="none"></path></g></svg>
                  </div>
                </div>
              </div>
            </a>
          }

          {displayFormat === 'toolbar' &&
            <a href={props.book.purchase.url} target="_blank" rel="noopener noreferrer">
              <div className="relative group cursor-pointer my-12">
                <Tooltip tooltipText="I publish select chapters of my books on my website for free. The full story is available for purchase here."></Tooltip>
                <h6 className="text-subheader-4 pb-4 flex items-center justify-center">
                  Purchase
                  <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 100" height="10px" width="10px"><path d="M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0Zm.62,75.53a1.48,1.48,0,0,0,1.4,0,13.37,13.37,0,0,0,2.34-2.17c1-1.19,1.88-2.48,2.87-3.82l1.39.84c-2,3.34-4.06,6.44-7.12,8.76a11.06,11.06,0,0,1-5.58,2.33c-4.77.54-7.82-2.71-6.68-7.39,1-4.11,2.27-8.16,3.43-12.24S45,53.52,46.18,49.38a10.35,10.35,0,0,0,.21-1.24,2.45,2.45,0,0,0-2.21-3.06A21.2,21.2,0,0,0,42,45a3.53,3.53,0,0,1,.11-.71l.14-1,17.24-2.76-1.2,4.18h0l-.27,1Q54.27,58.81,50.49,72A8.32,8.32,0,0,0,50,74.14,1.86,1.86,0,0,0,50.62,75.53ZM54.76,31a6.22,6.22,0,0,1-6.18-6.28,6.21,6.21,0,1,1,12.42,0A6.29,6.29,0,0,1,54.76,31Z" fill="currentColor"></path></svg>
                </h6>
                <div className="w-full text-mint py-4 flex justify-center items-center">
                  <div className="hover:text-lightblue active:text-black">
                    <svg fill="none" height="25px" width="25px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0,-952.36218)"><path fill="currentColor" d="m 8.0159573,961.36221 c -1.6569,0 -3,1.34315 -3,3 0,1.65685 1.3431,3 3,3 l 9.5624997,0 10.3125,48.65629 c 0.408,1.8316 1.6084,3.356 3.125,3.3438 l 50,0 c 1.5849,0.022 3.0427,-1.4149 3.0427,-3 0,-1.5851 -1.4578,-3.0224 -3.0427,-3 l -47.5625,0 -1.2813,-6 52.8438,0 c 1.3432,-0.01 2.6123,-1.0331 2.9062,-2.3438 l 7,-30.00004 c 0.3901,-1.74107 -1.122,-3.64346 -2.9062,-3.65625 l -67.4375,0 -1.625,-7.625 c -0.2839,-1.3321 -1.5755,-2.3764 -2.9375,-2.375 z m 17.8124997,16 15.625,0 1.5,9 -15.2187,0 z m 21.75,0 18.875,0 -1.5,9 -15.875,0 z m 25,0 15.6563,0 -2.0938,9 -15.0625,0 z m -43.5625,15 14.9375,0 1.5,9.00009 -14.5313,0 z m 21.0625,0 13.875,0 -1.5,9.00009 -10.875,0 z m 20,0 14.6563,0 -2.0938,9.00009 -14.0625,0 z m -28.0625,30.99999 c -5.4873,0 -10,4.5126 -10,10 0,5.4873 4.5127,10 10,10 5.4873,0 10,-4.5127 10,-10 0,-5.4873 -4.5127,-10 -10,-10 z m 28,0 c -5.4873,0 -10,4.5126 -10,10 0,5.4873 4.5127,10 10,10 5.4873,0 10,-4.5127 10,-10 0,-5.4873 -4.5127,-10 -10,-10 z m -28,6 c 2.2447,0 4,1.7553 4,4 0,2.2446 -1.7553,4 -4,4 -2.2445,0 -4,-1.7554 -4,-4 0,-2.2447 1.7555,-4 4,-4 z m 28,0 c 2.2447,0 4,1.7553 4,4 0,2.2446 -1.7553,4 -4,4 -2.2445,0 -4,-1.7554 -4,-4 0,-2.2447 1.7555,-4 4,-4 z" stroke="none"></path></g></svg>
                  </div>
                </div>
              </div>
            </a>
          }
        </>
      )}
    </>
  )
}
