import React from "react";
import { Link } from "gatsby"

export default function BreadcrumbTrail(props) {
  let makeOpaque = props.makeOpaque;
  if (makeOpaque === undefined || makeOpaque === null) {
    makeOpaque = true;
  }
  return (
    <h6 className={`text-breadcrumb ${makeOpaque ? 'text-breadcrumb--opaque' : ''} mb-8`}>
      {props.breadcrumbTrail.map((item, index) => (
        <span key={index}>
          {item.url && (
            <Link to={item.url}>{item.title}</Link>
          )}
          {!item.url && (
            <>
              {item.title}
            </>
          )}
          {index + 1 < props.breadcrumbTrail.length && (
            <>
              &nbsp;>&nbsp;
            </>
          )}
        </span>
      ))}
    </h6>
  )
}
