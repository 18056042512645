import React from "react";
import ArticleMetadata from "./article-metadata";
import { Link, graphql } from "gatsby"
import kebabCase from "lodash/kebabCase"
import TextBlock from "./text-block";

export default function ChapterSummary(props) {
  let summaryType = 'card';
  if (props.summaryType) {
    summaryType = props.summaryType;
  }

  return (
    <>
      {summaryType === 'card' &&
        <div className="flex-1 [height:100%]">
          <Link to={`/${props.chapter.slug}`}><h4 className="text-header-2 text-mint mb-8">{props.chapter.frontmatter.title}</h4></Link>
          <ArticleMetadata genreList={props.chapter.frontmatter.tags} publishedDate={props.chapter.frontmatter.formatted_published_date}></ArticleMetadata>
          <p className="text-body-1 my-8">Chapter {props.chapter.frontmatter.chapter} of <Link className="text-link italic" to={`/writing/library/${kebabCase(props.chapter.frontmatter.collection)}`}>{props.chapter.frontmatter.collection}</Link></p>
          <p className="text-body-1 italic">{props.chapter.frontmatter.summary}</p>
          <Link to={`/${props.chapter.slug}`}><button className="btn btn-medium btn-mint mt-10 sm:mt-12">Read More →</button></Link>
        </div>
      }
      {summaryType === 'cta' &&
        <div className="reading-w mx-auto">
          <Link to={`/${props.chapter.slug}`}><h4 className="text-header-2 text-mint text-center mb-8">{props.chapter.frontmatter.title}</h4></Link>
          <TextBlock text={`${props.chapter.frontmatter.summary.substring(0, 90)}...`}></TextBlock>
          <Link to={`/${props.chapter.slug}`}><button className="btn btn-medium btn-gold mt-10 sm:mt-12">Read More →</button></Link>
        </div>
      }
    </>
  )
}

export const query = graphql`
  fragment ArticleSnippet on Mdx {
    id
    slug
    excerpt(pruneLength: 250)
    ...ArticleFrontmatter
  }
`;